<template>
     <div class="page-table scrollable only-y mainDiv mb-100" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.sss.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh" class="routeButton">
                    </el-button>
                </el-tooltip>
            </div>    
        </div>
        <el-row :gutter="10">
            <el-col :lg="16" :md="16" :sm="24" :xs="24" :key="refreshMenu" v-loading="loading || stateLoading" 
            :element-loading-text='loading ? $t("src.views.apps.sss.liste.loading") : $t("src.views.apps.sss.liste.deleteLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                    <div v-if="sssListe.length > 0">
                        <el-row class="mb-10" type="flex" justify="center" v-for="(item, index) in sssListe" :key="index">
                            <el-col class="sss-card" :lg="23" :md="23" :sm="23" :xs="23">
                                <el-col :lg="21" :md="21" :sm="21" :xs="21" class="left">
                                    <p class="question animated flipInX">{{item.soru}}</p>
                                    <el-divider></el-divider>
                                    <p class="animated flipInX">{{item.cevap}}</p>
                                </el-col>
                                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="right m-1">
                                    <el-button @click="updateItem = item.sssID; startUpdate(item);"  icon="el-icon-edit" circle></el-button>
                                </el-col>
                                <el-col :lg="1" :md="1" :sm="1" :xs="1" class="right m-1">
                                    <el-button @click="removeSSS(item.sssID,index)"  icon="el-icon-delete" circle></el-button>
                                </el-col>
                            </el-col>

                            <el-col class="sss-card-answer" :lg="20" :md="20" :sm="20" :xs="20" :id="item.sssID">
                                <el-col :lg="23" :md="23" :sm="23" :xs="23" class="left">
                                    {{item.cevap}}
                                </el-col>
                            </el-col>

                        </el-row>
                        <el-row class="mb-20">
                            <el-pagination
                                background
                                layout="sizes,prev, pager, next, jumper, total"
                                :total="total"
                                @size-change="handleSizeChange"
                                @current-change="pageChange"
                                :page-sizes="[10, 20, 50, 100]"
                                :current-page.sync="page"
                                :next-click.sync="page"
                                :prev-click.sync="page"
                                @next-click="pageChange"
                                @prev-click="pageChange">
                            </el-pagination>
                        </el-row>
                    </div>
                    <div v-else style="height: 60vh; display: flex; justify-content: center; align-items: center">
                        {{$t("src.views.apps.sss.liste.noItem")}}
                    </div>
                </div>
            </el-col>

            <el-col :lg="8" :md="8" :sm="24" :xs="24" v-loading="addLoading || updateLoading" 
            :element-loading-text='loading ? $t("src.views.apps.sss.liste.addLoading") : $t("src.views.apps.sss.liste.updateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
                <el-collapse v-model="activeCollapse" class="yanForm animated fadeInUp">
                    <el-collapse-item class="card-shadow--medium" name="9" :title='$t("src.views.apps.sss.liste.defineSSS")'>
                        <el-form :model="sssForm" :rules="rulesSSS" ref="sssForm" class="demo-ruleForm">
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.sss.liste.question")' prop="question">:
                                        <el-input type="textarea" autosize v-model="sssForm.question"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.sss.liste.answer")' prop="answer">:
                                        <el-input type="textarea" :autosize="{ minRows: 2}" v-model="sssForm.answer"></el-input>
                                    </el-form-item>
                                </el-col>
                                
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <div style="display: flex; justify-content: end">
                                        <el-button @click="clearForm('sssForm')" type="danger">{{$t("src.views.apps.sss.liste.quit")}}</el-button>
                                        <el-button v-if="!updating" @click="defineSSS" type="primary">{{$t("src.views.apps.sss.liste.save")}}</el-button>
                                        <el-button v-if="updating" @click="updateSSS" type="warning">{{$t("src.views.apps.sss.liste.update")}}</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-collapse-item>
                </el-collapse>
            </el-col>

        </el-row>
    </div>
</template>

<script>
import sssService from "../../../WSProvider/SSSService";
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'

export default {
    data(){
        return {
            refreshMenu: 0,
            updating: false,
            updateItem: "",

            loading: false,
            addLoading: false,
            stateLoading: false,
            updateLoading: false,
            activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10','11','12','13','14', '15'],
            sssListe: [],

            rulesSSS: {
                question: {required: true,message: "Please Enter Question", trigger: 'blur'},
                answer: {required: true,message: "Please Enter Answer", trigger: 'blur'},
            },
            sssForm: {
                question: "",
                answer: "",
            },

            sizes: 10,
            postPage: 0,
            total: 0,
            page: 0,
        }
    },

    mounted(){
        this.getSSSListe();
        this.getSSSCount();
    },

    methods: {
        startUpdate(item){
            this.sssForm.question = item.soru;
            this.sssForm.answer = item.cevap;
            this.updating = true;
        },

        refreshPage(){
            this.page = 1;
            this.postPage = 0;
            this.loading = false
            this.stateLoading = false;
            this.addLoading = false;
            this.updateLoading = false;
            this.getSSSListe();
            this.getSSSCount();
        },

        getSSSListe(){
            this.loading = true;
            sssService.sssListe(this.postPage, this.sizes).then(response => {
                if(response.status == 200){
                    localStorage.setItem("userDataBGSurec", response.token);
                    response.data.forEach(element => {
                        element = functions.removeSlashesAndDecodeStrings(element)
                    });
                    this.sssListe = response.data;
                }
                this.loading = false;
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }else if(response.status == 404){
                        this.sssListe = [];
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                }else{
                    //notification.Status(503, this)
                }
                this.loading = false;
            })
        },

        getSSSCount(){
            sssService.sssCount('1').then(response => {
                if(response.status == 200){
                    localStorage.setItem("userDataBGSurec", response.token);
                    this.total = response.data
                }
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }else if(response.status == 404){
                        this.total = 0;
                    }
                }else{
                    //notification.Status(503, this)
                }
            })
        },

        defineSSS(){
            this.addLoading = true;
            sssService.sssEkle(this.sssForm.question, this.sssForm.answer).then(response => {
                if(response.status == 200){
                    localStorage.setItem("userDataBGSurec", response.token);
                    this.getSSSListe();
                    this.getSSSCount();
                }
                this.clearForm("sssForm");
                notification.Status("success", this, response.msg);
                this.addLoading = false;
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                }else{
                    //notification.Status(503, this)
                }
                this.addLoading = false;
            })
        },

        updateSSS(){
            this.updateLoading = true;
            sssService.sssGuncelle(this.updateItem, this.sssForm.question, this.sssForm.answer).then(response => {
                if(response.status == 200){
                    localStorage.setItem("userDataBGSurec", response.token);
                     this.getSSSListe();
                    this.getSSSCount();
                }
                this.clearForm("sssForm");
                notification.Status("success", this, response.msg);
                this.updateLoading = false;
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                }else{
                    //notification.Status(503, this)
                }
                this.updateLoading = false;
            })
        },

        removeSSS(sssID, index){
            this.stateLoading = true;
            sssService.sssSil(sssID).then(response => {
                if(response.status == 200){
                    localStorage.setItem("userDataBGSurec", response.token);
                    this.sssListe.splice(index, 1);
                }
                this.stateLoading = false
                notification.Status("success", this, response.msg);
            }).catch(err => {
                if(err.responseJSON){
                    let error = err.responseJSON
                    if(error.status == 401){
                        notification.Status(150, this);
                    }
                    else notification.Status("errorMsg", this, error.errMsg)
                }else{
                    //notification.Status(503, this)
                }
                this.stateLoading = false;

            })
        },


        clearForm(formName){
            this.$refs[formName].resetFields();
            this.updating = false;
        },

        handleSizeChange(val) {
            this.sizes = val;
            this.getSSSListe();
            this.getSSSCount();
        },
        pageChange(val) {
            this.postPage = (val - 1) * this.sizes;
             this.getSSSListe();
            this.getSSSCount();
            document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
        },

    },
}
</script>



<style lang="scss" scoped>
    @import '../../../assets/scss/_variables';

    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .sss-card{
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
        padding: 10px; 
        margin: 15px 0; 
        border-radius: 5px;

        .right {
            float: right;
        }

        .left{
            padding: 10px;
            word-wrap: break-word !important; 
        }

        &:hover{
            background: #f3f7fa;
            cursor: pointer;
        }

        .question{
            font-weight: bold;
            color: $text-color-accent;
        }
    }

    .sss-card-answer{
        display: none;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
        padding: 10px; 
        margin: 15px 0; 
        border-radius: 5px;
    }

    
</style>